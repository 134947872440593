@charset 'UTF-8';
.header {
	position: relative;
	left: auto;
	top: 0;
	z-index: 1000;
	width: 100%;
	padding: 34px 70px 5px;
	background: #fff;
  @include screen(1025px, 1198px) {
    padding: 34px 30px 5px;
  }
  @include screen(768px, 1024px) {
    padding: 34px 15px 5px;
  }
	.inHeader {
		margin: 0 auto;
		position: relative;
		z-index: 2000;
    @include min-screen(768px) {
      padding-left: 168px;
      padding-right: 130px;
    }
	}
	#logo {
		position: absolute;
		left: -7px;
		top: -11px;
		z-index: 1000;
		@include add_prefix(transition, 0.3s ease all);
		img {
			width: 149px;
			@include add_prefix(transition, 0.3s ease all);
		}
    .logo_bl{
      display: block;
      @include max-screen(767px) {
        display: block;
      }
    }
	}
  .logo_w{
    display: none;
  }
	@include max-screen(767px) {
		height: 54px;
		padding: 0;
    position: fixed;
		#logo {
      left: 20px;
      top: 14px;
			img {
				width: 126px;
			}
		}
		.hNavi {
			display: none;
		}
	}
  &__right{
    position: absolute;
    right: 0;
    top: -14px;
    z-index: 2;
    @include max-screen(767px) {
      @include clearfix();
      position: relative;
      top: 0;
      .ico_tel{
        position: absolute;
        right: 54px;
        top: 0;
        &:after{
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 4px 6px 4px;
          border-color: transparent transparent #ffffff transparent;
          position: absolute;
          bottom: 0;
          left: 50%;
          @include transform_c(translateX(-50%));
          opacity: 0;
        }
        &.active{
          &:after{
            opacity: 1;
          }
        }
      }
      .ico_mail{
        position: absolute;
        top: 0;
        right: 108px;
      }
    }
  }
  a.btn_header{
    min-width: 130px;
    display: inline-block;
    text-align: center;
    padding: 10px 15px;
    background: $color01;
    color: #fff;
    border: 2px solid $color01;
    border-radius: 44px;
    @include font-size(15);
    line-height: 20px;
    letter-spacing: 0.08em;
    font-weight: bold;
    font-family: $roboto;
    &:hover{
      opacity: 1;
      background: $color01;
      border-color: $color01;
    }
    &.btn_menu{
      border-radius: 0;
      border: none;
      @include font-size(17);
      letter-spacing: .32em;
      font-family: $roboto;
      margin-right: 15px;
      display: none;
      span{
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .phoneHeader{
    display: none;
    background: #fff;
    position: fixed;
    top: 54px;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 40px 40px 50px;
    @include IP5 {
      padding: 40px 30px 50px;
    }
    .closePhone{
      position: absolute;
      top: 26px;
      right: 45px;
    }
    .boxInfor{
      a{
        border-bottom: 1px solid #ccc;
        padding-bottom: 25px;
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
      &__inner{
        border: none;
        padding: 0;
        text-align: center;
        span{
          @include font-size(15);
          line-height: 18px;
          @include IP5 {
            @include font-size(14);
          }
          &.txt01{
            margin-bottom: 16px;
          }
          &.txtTel{
            @include font-size(34);
            line-height: 40px;
            font-weight: 300;
            margin-bottom: 10px;
            @include IP5 {
              @include font-size(30);
              line-height: 35px;
            }
            img{
              max-width: 23px;
            }
          }
          &.txtCall{
            border: 1px solid #4d4d4d;
            border-radius: 15px;
            @include font-size(13);
            line-height: 29px;
          }
        }
      }
    }
  }
}

.top{
  .header{
    position: absolute;
    background: none;
    .layerMenu{
      display: none;
      .gNavi>li>a{
        color: #fff;
        @include max-screen(767px) {
          color: #000;
        }
      }
    }
    .logo_w{
      display: block;
      // visibility: hidden;
      &.animated{
        visibility: visible;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
      }
    }
    #logo{
      @include min-screen(768px) {
        top: 12px;
        left: 0;
        img{
          width: 168px;
        }
      }
      .logo_bl{
        display: none;
      }
    }
    a.btn_header{
      border-color: #fff;
      color: #fff;
      background: none;
      &:hover{
        border-color: $color01;
        color: #fff;
        background: $color01;
      }
      &.btn_menu{
        @include min-screen(1026px) {
          display: inline-block;
        }
      }
    }


    .header__right{
      @include min-screen(768px) {
        top: 8px;
      }
    }
  }
  &.fixHeader,&.addFix{
    .header{
      position: fixed;
      background: #fff;
      .layerMenu .gNavi>li>a{
        color: #4D4D4D;
      }
      .hamberger{
        @include min-screen(768px) {
          display: none;
        }
      }
      a.btn_menu{
        display: none;
      }
    }

  }
  &.layerOn{
    .header{
      .layerMenu{
        display: block;
      }
    }
  }
}

.fixHeader,.addFix{
	.header {
		position: fixed;
		left: 0;
		top: 0;
    background: #fff;
    #logo{
      top: -12px;
      @include max-screen(767px) {
        top: 12px;
      }
      .logo_w{
        display: none;
      }
      .logo_bl{
        display: block;
      }
    }
    .layerMenu{
      display: block;
    }
    .hamberger{
      @include min-screen(768px) {
        background: $color01;
      }
    }
    a.btn_header{
      background: $color01;
      border-color: $color01;
    }
    .header__right{
      @include min-screen(768px) {
        top: -14px;
      }
    }
	}
}

.layerMenu {
	text-align: center;
	position: relative;
  display: block;
	.gNavi {
    display: inline-block;
		> li {
			display: inline-block;
			vertical-align: top;
			width: auto;
			position: relative;
      padding: 0 8px;
      @include screen(1025px, 1198px) {
        padding: 0 6px;
      }
      @include screen(768px,1024px) {
        padding: 0 5px;
      }
			> a {
				display: block;
				position: relative;
        @include font-size(14);
        line-height: 16px;
        letter-spacing: 0.06em;
        padding: 0 0 27px;
        color: #4D4D4D;
        @include screen(1025px, 1198px) {
          @include font-size(12);
        }
				&:before {
					// content: '';
					position: absolute;
					right: 2px;
					top: 50%;
					width: 8px;
					height: 8px;
					margin-top: -6px;
					border-left: 1px solid #000;
					border-top: 1px solid #000;
					@include transform_c(rotate(-135deg));
					@include add_prefix(transition, 0.3s ease all);
					display: none;
				}
				&:after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					background: $color01;
          // animation: hover-slideBg-out 0.3s cubic-bezier(0.16, 0.84, 0.44, 1) 0s 1 normal forwards running;
					// @include transform_c(scaleX(0));
					@include add_prefix(transition, 0.3s ease all);
				}
				&:hover,&.active {
          opacity: 1;
					&:after {
            // animation: hover-slideBg-in 0.3s cubic-bezier(0.16, 0.84, 0.44, 1) 0s 1 normal forwards running;
            // @include transform_c(scaleX(1));
            width: 100%;
					}
				}
			}
		}
	}
	@include max-screen(767px) {
		position: fixed;
		right: -100%;
		top: 0;
		z-index: 1000;
		width: 100%;
		height: 100%;
		text-align: left;
		background: #fff;
		.inLayer {
			position: relative;
			height: 90%;
			padding: 0 30px 30px;
			overflow: auto;
		}
    .hamberger_close{
      text-align: right;
    }
		.gNavi {
			margin-bottom: 30px;
      display: block;
      width: 100%;
      margin-top: 20px;
			> li {
				display: block;
				width: 100%;
				margin: 0;
        border-top: 1px solid #f5f5f5;
        @include font-size(15);
        line-height: 16px;
        padding: 14px 0;
        &.txtTitle_Cate{
          font-family: $roboto;
          @include font-size(12);
          padding-bottom: 5px;
          padding-top: 40px;
        }
				> a {
					color: #000;
					padding: 0;
          &:after{
            display: none;
          }
				}
        &.subNab{
          position: relative;
          border-bottom: 1px solid #f5f5f5;
          > span{
            display: block;
            position: relative;
            &:after,&:before{
              content: "";
              background: #000;
              position: absolute;
              @include transition_c(all 0.3s);
            }
            &:after{
              width: 1px;
              height: 12px;
              top: 2px;
              right: 5px;
            }
            &:before{
              width: 12px;
              height: 1px;
              top: 7px;
              right: 0;
            }
          }
          &.active{
            border-bottom: none;
            > span{
              &:after{
                height: 0;
              }
            }
          }
        }
        ul{
          padding-top: 15px;
          display: none;
          li{
            border-bottom: 1px solid #fff;
            a{
              display: block;
              background: #f5f5f5;
              padding: 14px 24px;
            }
          }
        }
			}
		}
		.btnmenu_sp{
      background: $color01;
      color: #fff;
      text-align: center;
      display: block;
      @include font-size(16);
      line-height: 52px;
    }
	}
	@include min-screen(768px) {
		position: relative !important;
		right: auto;
		top: auto;
	}
}

.layerOn {
	//overflow: hidden;
	.layerMenu {
		right: 0;
	}
}

.hamberger {
	position: relative;
	right: 0;
	top: 0;
	z-index: 1000;
	font-size: 0;
  line-height: 0;
	text-align: center;
	cursor: pointer;
  vertical-align: middle;
  padding: 8px 15px 7px;
  margin-right: 17px;
  padding-left: 48px;
  @include transition_c(all 0.3s);
  display: none;
  @include min-screen(768px) {
    &:hover{
      background: $color01;
    }
  }
  @include max-screen(767px) {
    width: 54px;
    height: 54px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
  .menu{
    @include font-size(17);
    line-height: 29px;
    letter-spacing: 0.32em;
    font-family: $roboto;
    font-weight: bold;
    color: #fff;
    font-style: normal;
    // position: absolute;
    // right: -75px;
    // top: 0;
  @include max-screen(767px) {
    color: #000;
    @include font-size(9);
    line-height: 16px;
    letter-spacing: 0.1em;
    position: absolute;
    bottom: 7px;
    left: 0;
    width: 100%;
  }
  }
	span {
		display: inline-block;
		width: 20px;
		height: 3px;
		background: #fff;
    position: absolute;
    top: 13px;
    left: 12px;
		@include add_prefix(transition, 0.3s ease all);
    @include max-screen(767px) {
      background: #000;
      height: 2px;
      left: 17px;
    }
    &.ber{
      margin-bottom: 0;
      top: 26px;
      @include max-screen(767px) {
        top:24px;
      }
    }
	}
	&.active {
    @include min-screen(768px) {
      background: $color01;
    }
		span {
			width: 20px;
		}
		.ham { @include transform_c(rotate(45deg) translateY(6px) translateX(6px)); }
		.ber { @include transform_c(rotate(-45deg) translateX(2px) translateY(-2px)); }
		.ger { display: none; }
	}
	@include max-screen(767px) {
		display: block;
	}
}

@-webkit-keyframes hover-slideBg-in {
  0% {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  to {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes hover-slideBg-in {
  0% {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  to {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes hover-slideBg-out {
  0% {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  to {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
}

@keyframes hover-slideBg-out {
  0% {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  to {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
}

@include min-screen(768px) {
  .top .layerMenu .gNavi>li:first-child a:after{
    width: 100%;
  }
  .service .layerMenu .gNavi>li:nth-child(2) a:after{
    width: 100%;
  }
  .products .layerMenu .gNavi>li:nth-child(3) a:after{
    width: 100%;
  }
  .solution .layerMenu .gNavi>li:nth-child(4) a:after{
    width: 100%;
  }
  .event .layerMenu .gNavi>li:nth-child(5) a:after{
    width: 100%;
  }
  .download .layerMenu .gNavi>li:nth-child(6) a:after{
    width: 100%;
  }
  .company .layerMenu .gNavi>li:last-child a:after{
    width: 100%;
  }
}

// fix ie
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .header{
    padding-bottom: 0;
  }
  .layerMenu .gNavi{
    position: relative;
    top: -6px;
  }
}