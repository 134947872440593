.footer{
  position: relative;
  &__contact{
    display: table;
    width: 100%;
    height: 510px;
    background: url('../img/top/bg_contact.jpg') center/cover no-repeat;
    color: #fff;
    text-align: center;
    @include max-screen(767px) {
      height: 410px;
      padding: 0 30px;
    }
    &__inner{
      display: table-cell;
      vertical-align: middle;
    }
    &__title{
      @include font-size(40);
      line-height: 70px;
      letter-spacing: 0.02em;
      font-weight: 100;
      font-family: $roboto;
      margin-bottom: 26px;
      @include max-screen(767px) {
        @include font-size(37);
        line-height: 64px;
      }
    }
    a.btn_contact{
      color: #fff;
      position: relative;
      display: block;
      max-width: 840px;
      margin: 0 auto;
      text-align: center;
      @include font-size(17);
      line-height: 30px;
      letter-spacing: 0.34em;
      font-weight: 500;
      border: 1px solid #fff;
      padding: 35px;
      @include max-screen(767px) {
        line-height: 28px;
        letter-spacing: 0.28em;
        padding: 24px;
      }
      @include max-screen(320px) {
        @include font-size(16);
        letter-spacing: 0.01em;
      }
      &:after{
        content: "";
        width: 10px;
        height: 13px;
        background: url('../img/common/ar_w.svg') center/100% no-repeat;
        position: absolute;
        right: 36px;
        top: 50%;
        @include transform_c(translateY(-50%));
        @include max-screen(767px) {
          right: 15px;
        }
      }
      &:hover{
        opacity: 1;
        background: #fff;
        color: #000;
        &:after{
          background: url('../img/common/ar_bl.svg') center/100% no-repeat;
        }
      }
    }
  }
  &__infor{
    position: relative;
    @include clearfix();
    &__item{
      float: left;
      width: 50%;
      position: relative;
      padding: 114px 20px 100px;
      text-align: center;
      @include max-screen(767px) {
        padding: 68px 20px 35px;
      }
      @include max-screen(320px) {
        width: 100%;
      }
      &:last-child{
        background: #F7F7F7;
      }
      &:before{
        content: "";
        width: 1px;
        height: 360px;
        background: #808080;
        position: absolute;
        left: 0;
        top: 0;
        @include transform_c(rotate(45deg));
        left: 255px;
        transform-origin: top;
        @include max-screen(767px) {
          height: 98px;
          left: 69px;
        }
      }
    }
    &__title{
      @include font-size(12);
      line-height: 23px;
      letter-spacing: 0.02em;
      margin-bottom: 30px;
      @include max-screen(767px) {
        line-height: 15px;
        margin-bottom: 18px;
      }
      span{
        display: block;
        @include font-size(34);
        font-weight: 100;
        font-family: $roboto;
        margin-bottom: 4px;
        @include max-screen(767px) {
          @include font-size(26);
          line-height: 45px;
        }
      }
    }
    &__txt{
      @include font-size(13);
      line-height: 26px;
      letter-spacing: 0.08em;
      margin-bottom: 15px;
      @include max-screen(767px) {
        text-align: left;
        @include font-size(13);
        line-height: 22px;
        letter-spacing: 0.04em;
        margin-bottom: 20px;
      }
      a{
        pointer-events: none;
        @include max-screen(767px) {
          pointer-events: auto;
        }
      }
    }
    &__link{
      @include font-size(13);
      line-height: 24px;
      letter-spacing: 0.08em;
      font-family: $roboto;
      text-decoration: underline !important;
      display: inline-block;
      @include max-screen(767px) {
        text-align: left;
        display: block;
        line-height: 22px;
      }
    }
  }
  &__nav{
    border-top: 1px solid #F7F7F7;
    padding: 45px 0 70px;
    @include clearfix();
    max-width: 80%;
    margin: 0 auto;
    @include flexbox();
    @include justify-content(space-between);
    &__col{
      width: 20%;
      // float: left;
      @include font-size(13);
      line-height: 27px;
      letter-spacing: 0.02em;
      h4{
        margin-bottom: 5px;
      }
      ul{
        li{
          &.linkContact{
            margin-bottom: 22px;
            a.noLink{
              pointer-events: none;
            }
          }
          a.noLink{
            pointer-events: none;
          }
        }
      }
    }
  }
  &__copyright{
    text-align: center;
    position: relative;
    padding: 50px 70px 35px;
    @include max-screen(767px) {
      text-align: left;
      padding: 20px;
      border-top: 1px solid #F7F7F7;
    }
    .img_footer{
      position: absolute;
      left: 70px;
      top: 0;
      @include max-screen(767px) {
        position: relative;
        max-width: 196px;
        left: 0;
        margin-bottom: 10px;
        display: block;
      }
    }
    .linkPrivacy{
      display: inline-block;
      @include font-size(11);
      line-height: 15px;
      letter-spacing: 0.04em;
      margin-bottom: 10px;
    }
    span{
      @include font-size(11);
      line-height: 20px;
      letter-spacing: 0.04em;
      @include max-screen(767px) {
        text-align: left;
        display: block;
        line-height: 15px;
      }
    }
  }
  .footetTotop{
    position: absolute;
    right: 70px;
    bottom: 0;
    @include max-screen(767px) {
      right: 25px;
      bottom: 22px;
    }
    img{

    }
  }
  .btnBacTop{
    position: fixed;
    right: 50px;
    bottom: 40px;
    z-index: 4;
    display: none;
    @include max-screen(767px) {
      width: 32px;
      right: 22px;
      bottom: 22px;
    }
  }
}