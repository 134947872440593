@charset 'UTF-8';

/*-----------------------------------------------------------
Bread
------------------------------------------------------------*/
.box_breadcrumb{
  overflow: hidden;
  @include max-screen(767px) {
    border-bottom: 1px solid #E2E2E2;
    padding: 5px 0;
    margin-bottom: 36px;
  }
}
.breadcrumb{
  margin-bottom: 33px;
  margin-left: 2px;
  max-height: 30px;
  @include flexbox;
  @include justify-content(flex-start);
  @include SP{
    margin: 0;
    // overflow-x: scroll;
    // white-space: nowrap;
    // overflow-y: hidden;
  }
  li{
    display: inline;
    vertical-align: top;
    color: #4D4D4D;
    @include font-size(12);
    line-height: 24px;
    letter-spacing: 0.06em;
    position: relative;
    padding-right: 14px;
    margin-right: 12px;
    @include SP{
      @include font-size(11);
      padding-right: 12px;
      margin-right: 7px;
    }
    &:nth-child(3){
      width: calc(100% - 200px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @include SP() {
        width: calc(100% - 160px);
      }
    }
    &:last-child{
      margin-right: 0;
      padding-right: 0;
    }
    &:after{
      content: '>';
      width: 6px;
      height: 6px;
      display: inline;
      vertical-align: middle;
      position: absolute;
      top: 1px;
      right: 0;
      @include SP{
        margin: 0 1px 0 5px;
      }
    }
    a{
      color: #4D4D4D !important;
      display: inline;
      vertical-align: middle;
      font-weight: bold;
    }
    span{
      display: inline;
      position: relative;
      top: 2px;
    }
    &:last-child{
      &:after{
        content: none;
      }
    }
  }
}

// fix ie
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .breadcrumb li span{
    top: 0;
  }
}