a.btn_cm,.btn_cm{
  max-width: 296px;
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 14px 15px;
  border: 1px solid #000;
  border-radius: 25px;
  @include font-size(13);
  line-height: 20px;
  letter-spacing: 0.04em;
  font-weight: 500;
  &:hover{
    @include HOVER {
      opacity: 1;
      background: #000;
      color: #fff;
    }
  }
  &__blue{
    background: $color01;
    color: #fff;
    border-color: $color01;
    font-weight: bold;
    @include max-screen(767px) {
      letter-spacing: 0.08em;
    }
  }
  &__gray{
    background: #A5A5A5;
    color: #fff;
    border-color: #A5A5A5;
    font-weight: bold;
  }
}
.title_cm{
  text-align: center;
  @include font-size(13);
  letter-spacing: 0.12em;
  font-weight: 500;
  margin-bottom: 18px;
  position: relative;
  z-index: 2;
  @include max-screen(767px) {
    margin: 15px;
  }
  &__en{
    @include font-size(40);
    line-height: 45px;
    letter-spacing: 0.02em;
    font-family: $roboto;
    font-weight: 100;
    display: block;
  }
}

.top,.solution{
  .owl-carousel{
    .owl-nav{
      .owl-prev,.owl-next{
        position: absolute;
        top: 50%;
        @include transform_c(translateY(-50%));
        left: 0;
        font-size: 0;
        width: 36px;
        height: 36px;
        background: url('../img/top/ar_prev.svg') center no-repeat;
        z-index: 12;
        &.disabled{
          display: none;
        }
      }
      .owl-next{
        background: url('../img/top/ar_next.svg') center no-repeat;
        left: auto;
        right: 0;
      }
    }
    .owl-dots{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      vertical-align: bottom;
      line-height: 0;
      @include max-screen(767px) {
        bottom: -30px;
      }
      .owl-dot{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #DBDBDB;
        margin: 0 10px;
        &.active{
          width: 14px;
          height: 14px;
          background: #000;
        }
      }
    }
  }
}

.title_subPage{
  text-align: center;
  padding: 12px 15px 9px;
  @include font-size(17);
  line-height: 30px;
  letter-spacing: 0em;
  background: #D6D6D6;
  margin-bottom: 6px;
  @include max-screen(767px) {
    @include font-size(15);
    line-height: 30px;
    margin-bottom: 0;
    padding: 10px;
  }
  span{
    display: inline-block;
    vertical-align: middle;
  }
  &__en{
    font-weight: 500;
    font-family: $roboto;
    @include font-size(21);
    line-height: 37px;
    letter-spacing: 0.06em;
    padding-right: 16px;
    margin-right: 14px;
    position: relative;
    @include max-screen(767px) {
      @include font-size(18);
      margin-right: 5px;
      padding-right: 8px;
    }
    &:after{
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      height: 20px;
      background: #000;
      @include transform_c(translateY(-50%));
      margin-top: -3px;
      @include max-screen(767px) {
        height: 14px;
        margin-top: 0;
      }
    }
  }
}

.line_rotate{
  position: relative;
  overflow: hidden;
  &:before{
    content: "";
    width: 1px;
    height: 360px;
    background: #808080;
    position: absolute;
    left: 255px;
    top: 0;
    @include transform_c(rotate(45deg));
    // left: 16%;
    transform-origin: top;
    @include max-screen(767px) {
      height: 155px;
      left: 109px;
    }
  }
}

.imagetb {
  display: block;
  position: relative;
  overflow: hidden;
  background: center no-repeat;
  background-size: cover;
  @include transition_c(all 0.4s);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 4s;
  transition-duration: 4s;
  -webkit-transition-timing-function: cubic-bezier(.075,.82,.165,1);
  transition-timing-function: cubic-bezier(.075,.82,.165,1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
}

.imagetb img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  display: none;
}
.imagetb-520x338 {
  @include aspect-ratio(520, 338);
}
.imagetb-272x197 {
  @include aspect-ratio(272, 197);
}
.imagetb-372x235 {
  @include aspect-ratio(744, 470);
}
.imagetb-108x75{
  @include aspect-ratio(108, 75);
}

.lbNew{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transform: none !important;
  max-width: 46px;
  @include max-screen(767px) {
    max-width: 46px;
  }
}

@include PC {
  .owl-carousel{
    display: block;
  }
}
.owl-dots{
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  text-align: center;
  position: absolute;
  span{
    display: inline-block;
    width: 11px;
    height: 11px;
    background: #DBDBDB;
    border-radius: 50%;
  }
  button{
    display: inline-block;
    margin: 0 10px;
  }
  .active{
    position: relative;
    top: 2px;
    span{
      width: 14px;
      height: 14px;
      background: #000;
    }
  }
}
.commonslide{
  &__bllink{
    background: #EAEFF7;
    padding: 74px 12px;
    overflow: hidden;
    position: relative;
    &__ttl{
      font-size: 23px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.06em;
      line-height: 37px;
    }
    &__list{
      max-width: 1104px;
      margin: 40px auto 0;
      font-size: 0;
      letter-spacing: 0;
      text-align: center;
      &:not(.owl-loaded){
        .item{
          display: inline-block;
          vertical-align: top;
          width: 25%;
          padding: 0 8px;
        }
      }
      a{
        display: block;
      }
      .img{
        overflow: hidden;
        img{
          transition: 0.5s all ease;
        }
      }
      .ttl{
        font-size: 17px;
        line-height: 28px;
        margin-top: 20px;
        text-align: center;
      }
      @include PC {
        a:hover{
          opacity: 1;
          img{
            @include transform_c(scale(1.1));
          }
        }
      }
    }
    @include SP {
      padding: 40px 20px 90px;
      &:before{
        content: '';
        width: 20px;
        height: 100%;
        background: #EAEFF7;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: 2;
      }
      &__ttl{
        font-size: 21px;
      }
      &__list{
        margin-top: 20px;
        padding-right: 60%;
        .ttl{
          font-size: 14px;letter-spacing: 0.05em;
          margin-top: 10px;
        }
      }
      .owl-stage-outer{
        overflow: initial;
      }
    }
  }
}

.boxInfor{
  @include clearfix();
  margin: 0 -7px;
  @include max-screen(767px) {
    margin: 0;
  }
  a{
    display: block;
    float: left;
    width: 50%;
    padding: 0 7px;
    @include max-screen(767px) {
      float: none;
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &__inner{
    display: block;
    background: #fff;
    border: 1px solid #CCC;
    padding: 45px 15px;
    @include max-screen(767px) {
      padding: 25px 10px 24px;
    }
    span{
      display: block;
      @include max-screen(767px) {
        @include font-size(16);
        line-height: 24px;
        letter-spacing: 0.02em;
      }
      @include max-screen(360px) {
        @include font-size(13);
      }
      &.txt01{
        margin-bottom: 22px;
        @include max-screen(767px) {
          margin-bottom: 25px;
        }
      }
      &.txtTel{
        @include font-size(38);
        letter-spacing: 0.02em;
        font-family: $roboto;
        font-weight: 100;
        position: relative;
        margin-bottom: 5px;
        @include max-screen(360px) {
          @include font-size(30);
        }
        img{
          margin-right: 9px;
          position: relative;
          top: -3px;
          @include max-screen(767px) {
            margin-right: 6px;
            max-width: 25px;
          }
          @include max-screen(360px) {
            max-width: 20px;
          }
        }
      }
      &.txttime{

      }
      &.txtCall{
        @include font-size(14);
        line-height: 24px;
        letter-spacing: 0.02em;
        margin-top: 15px;
        @include max-screen(360px) {
          @include font-size(12);
        }
      }
    }
  }
}

// fix ie
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a.btn_cm{
    padding: 16px 15px 13px;
  }
  .title_subPage{
    padding-top: 10px;
  }
  .title_subPage__en:after{
    margin-top: -1px;
  }
  .title_subPage__ja{
    position: relative;
    top: 2px;
  }
}